

if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    $('[data-toggle="popover"]').popover('disable');
}
else{
  $('[data-toggle="popover"]').popover();

  $('.service__detailTopUl li a').on('click', function(event) {
    var a = $(this).attr('data-toggle','modal');
    $('#myModal').appendTo("body");
  });
}
