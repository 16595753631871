// 大小雙 swiper
var swiper = new Swiper(".mySwiper", {
  // loop: true,
  spaceBetween: 0,
  slidesPerView: 6,
  freeMode: true,
  watchSlidesProgress: true,
  watchSlidesVisibility: true,

});
var swiper2 = new Swiper(".mySwiper2", {
  // loop: true,
  spaceBetween: 10,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  thumbs: {
    swiper: swiper,
  },
});


var swiper3 = new Swiper(".mySwiper3", {
  // loop: true,
  spaceBetween: 0,
  slidesPerView: 10,
  freeMode: true,
  watchSlidesProgress: true,
  watchSlidesVisibility: true,

});
var swiper4 = new Swiper(".mySwiper4", {
  // loop: true,
  spaceBetween: 10,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  thumbs: {
    swiper: swiper3,
  },
});


var swipercase = new Swiper(".swiperCase", {
  // loop: true,
  spaceBetween: 10,
  slidesPerView: 3,
  loop: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});
